/** @jsx jsx */
import { jsx, Text, Link } from 'theme-ui';
import { Link as GatsbyLink } from 'gatsby';

// import { Link } from 'components/link';
// Image
// import logo from 'assets/images/Logo_Dark_Green.png';
// import logoWhite from 'assets/images/Logo_Contrast_white.png';

const text = () => {
  return (
    <Text
      sx={{
        fontSize: 40,
        fontWeight: 'bold',
        color: 'black'
      }}
    >
      Qomix
    </Text>
  )
}

const textWhite = () => {
  return (
    <Text
      sx={{
        fontSize: 40,
        fontWeight: 'bold',
        color: "white"
      }}
    >
      Qomix
    </Text>
  )
}

export default function Logo({ isSticky, light, dark, ...props }) {
  return (
    // <Link sx={styles.logo} {...props}>
    <div>
      {light ? (
        textWhite()
        // <Image sx={styles.img} src={logoWhite} alt="startup landing logo" />
      ) : dark ? (
        text()
        // <Image sx={styles.img} src={logo} alt="startup landing logo" />
      ) : (
        (isSticky ? text() : textWhite())
        // <Image sx={styles.img} src={isSticky ? logo : logoWhite} alt="startup landing logo" />
      )}
    </div>
    // </Link>
  );
}
const styles = {
  logo: {
    alignItems: 'center',
    cursor: 'default',
    display: 'inline-flex',
    img: {
      // maxWidth: [128, null, '100%'],
      // width: "10px",
      // maxWidth: '10%',
    },
    textDecoration: 'none'
  },
  img: {
    maxWidth: "200px",
  }
};
