import WebsiteData from "../../../data/WebsiteData";
const custom_menuItems = WebsiteData['Header']['menuItems']

const menuItems = custom_menuItems // [
//   {
//     path: 'home',
//     label: 'Home',
//   },
//   {
//     path: 'testimonials',
//     label: 'Testimonials',
//   },
//   {
//     path: 'features',
//     label: 'Features',
//   },
//   {
//     path: 'services',
//     label: 'Services',
//   },
//   {
//     path: 'faq',
//     label: 'Faq',
//   },
// ];
export default menuItems;
